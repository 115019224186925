<template lang="pug">
a-modal(
  title="生成" 
  ok-text="确认" 
  cancel-text="取消" 
  v-model:visible="isShowGenerateDialog" 
  @ok="handleSubmitGenerate"
)
  a-form
    a-form-item(label="预览")
      img.imagePreview(:src="generateData.previewUrl")
    a-form-item(v-for="(i,n) in generateData.placeholderList" :key="n" :label="'组件 - '+(n+1)")
      a-form.placeholderItem
        .name
          .type {{i.type}}
          .placeholderKey {{i.placeholderKey}}
        .placeholderValue
          a-input(size="small" v-model:value="i.placeholderValue")
          

</template>

<script setup>
import { inject, onMounted } from 'vue'
const { route } = inject('global')
import { generateData, isShowGenerateDialog, handleSubmitGenerate } from '../provider/index'
</script>

<style lang="less" scoped>
.imagePreview {
  max-width: 200px;
  max-height: 200px;
}
.placeholderItem {
  .name {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .type {
      background: var(--color-red);
      color: white;
      padding: 0px 5px;
      border-radius: 10px;
      margin-right: 10px;
    }
  }
}
</style>
