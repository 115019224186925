<template lang="pug">
a-modal(
  title="预览" 
  ok-text="确认" 
  cancel-text="取消" 
  width="900px"
  v-model:visible="isShowPreviewDialog" 
  @ok="isShowPreviewDialog=false"
)
  a-form
    a-form-item(label="预览")
      img.imagePreview(:src="previewData.previewUrl")

</template>

<script setup>
import { inject, onMounted } from 'vue'
const { route } = inject('global')
import { previewData, isShowPreviewDialog } from '../provider/index'

const foo = 'bar'
</script>

<style lang="less" scoped>
.imagePreview {
  max-width: 800px;
  max-height: 800px;
}
</style>
