<template lang="pug">
a-tabs(v-model:activeKey="currentCategory")
  a-tab-pane(v-for="i in tabList" :key="i.id" :tab="i.name")
.templateList
  .templateItem(v-for="(i,n) in templateList" :key="n")
    img.image(:src="i.previewUrl")
    .name {{i.name}}
    .action
      .btn(@click="handleEdit(i)") 编辑
      .btn(@click="handleDelete(i)") 删除
      .btn(@click="handleGenerate(i)") 生成
.pagination
  a-pagination(v-model:current="pagination.page" :total="pagination.total")

.floatBtn
  a-button(type="primary" shape="circle" @click="$router.push('/editor/workspace')")
    template(#icon)
      PlusOutlined

comGenerateDialog
comPreviewDialog

</template>

<script setup>
import { provide, inject, onMounted, onBeforeMount, onUnmounted, watch, computed, ref } from 'vue'
const { router } = inject('global')
import { PlusOutlined } from '@ant-design/icons-vue'

import {
  getTemplateList,
  templateList,
  pagination,
  handleDelete,
  handleGenerate,
  currentCategory
} from './provider/index'
import { postTypeList } from '@/provider/dict'
import comGenerateDialog from './module/generateDialog.vue'
import comPreviewDialog from './module/previewDialog.vue'

const handleEdit = item => {
  router.push(`/editor/workspace?id=${item._id}`)
}

onMounted(() => {
  getTemplateList()
})

const tabList = computed(() => {
  return [{ id: '', name: '全部' }].concat(postTypeList.value)
})
</script>

<style lang="less" scoped>
.templateList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.templateItem {
  border: 1px solid var(--color-green);
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  .image {
    width: 200px;
  }
  .name {
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    color: var(--color-green);
  }
  .action {
    display: flex;
    .btn {
      flex-basis: 50%;
      text-align: center;
      cursor: pointer;
      font-size: 12px;
      color: var(--color-red);
      border-right: 1px solid var(--color-green);
      &:last-child {
        border-right: none;
      }
      &:hover {
        background: var(--color-red);
        color: white;
      }
    }
  }
  &:hover {
    box-shadow: 2px 2px 6px var(--color-green);
  }
}
.pagination {
  text-align: center;
  padding: 20px;
}
.floatBtn {
  position: fixed;
  right: 30px;
  bottom: 30px;
}
</style>
