import { ref, createVNode, watch } from 'vue'
import { app } from '@/main'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

const fetch = window.$globalUtil.axios

export let currentCategory = ref('')
export let pagination = ref({
  page: 1,
  size: 10,
  total: 10
})

export const templateList = ref([])

export const getTemplateList = () => {
  let params = { ...pagination.value }
  if (currentCategory.value) {
    params.category = currentCategory.value
  }
  fetch
    .get('/boom-px-api-service/template', {
      params
    })
    .then(res => {
      templateList.value = res.list
      pagination.value = res.pagination
    })
}

export let isShowGenerateDialog = ref(false)

export let generateData = ref({
  _id: '',
  previewUrl: '',
  placeholderList: []
})

export let isShowPreviewDialog = ref(false)
export let previewData = ref({
  previewUrl: ''
})

export const handleDelete = item => {
  Modal.confirm({
    title: '确定要删除当前模板吗?',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    icon: createVNode(ExclamationCircleOutlined),
    content: createVNode('div', { style: 'color:red;' }, '删除后不可恢复'),
    onOk() {
      fetch.delete(`/boom-px-api-service/template/${item._id}`).finally(() => {})
    },
    onCancel() {
      console.log('Cancel')
    },
    class: 'test'
  })
}

export const handleGenerate = item => {
  generateData.value._id = item._id
  generateData.value.previewUrl = item.previewUrl
  generateData.value.placeholderList = getComponentList(item.spriteList)
  isShowGenerateDialog.value = true
}

export const handleSubmitGenerate = () => {
  fetch
    .post(`/boom-px-api-service/template/${generateData.value._id}/generate`, {
      placeholderList: generateData.value.placeholderList
    })
    .then(res => {
      if (res) {
        isShowGenerateDialog.value = false
        previewData.value.previewUrl = res
        isShowPreviewDialog.value = true
      }
    })
}

const getComponentList = spriteList => {
  return spriteList
    .filter(i => !!i.placeholderKey)
    .map(i => {
      return {
        type: i.type,
        placeholderKey: i.placeholderKey,
        placeholderValue: i.text || i.url
      }
    })
}

watch(
  () => currentCategory.value,
  () => {
    getTemplateList()
  }
)
